/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import { LocalizedLink, Wrapper, SliceZone, Title, SEO, Headline } from '../components'
import { ProjectListing, DescriptionCoolLink, DescriptionPair, Categories } from '../components/Listing/'
import { LocaleContext } from '../components/Layout'
import website from '../../config/website'

import {DateRange as DateIcon, PersonOutline as AuthorIcon} from 'emotion-icons/material'

const HeadlineWrapper = styled(Headline)`
  font-size: 1.25rem;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-flow: column;
  }
`

const ProjectWrapper = Wrapper.withComponent('main')
const ProjectArticle = styled.article``
const ProjectHeader = styled.header`
  display: flex;
  flex-flow: row nowrap;
  place-items: center;
  height: 60vh;
  padding-top: 1rem;
`

const HeaderImg = styled.div`
  opacity: 1;
  transform: none;
  width: 40%;
  height: unset;

  > div {
    max-height: 60vh;

    Img {
      object-fit: contain !important;
      border-radius: 8%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    overflow: hidden;
    width: 100vw;
    height: 60vh;
    background-image: linear-gradient(to top, ${props => props.theme.colors.bg} 0%, transparent 300%);
    position: absolute !important;
    top: 0;
    left: 0;

    > div {
      z-index: -1;
      height: 100%;
      
      Img {
        object-fit: cover !important;
        border-radius: unset;
      }
    }
  }
`

const headerImgAnim = keyframes`
  0%, 20% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`

const HeaderText = styled.div`
  margin: auto auto auto 2rem;
  z-index: 2;
  overflow: hidden;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: auto -1rem auto 1rem;
  }
`

const ProjectAside = styled.aside``

const Project = ({ data: { prismicProject, projects }, location, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const { data } = prismicProject
  let categories
  if (data.categories[0].category) {
    categories = data.categories.map(c => c.category.document[0])
  }

  return (
    <>
      <SEO
        title={`${data.title.text} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
        desc={data.description}
        node={prismicProject}
        pageType={i18n.pages.projects.name}
        isArticle
      />
      <ProjectWrapper id={website.skipNavId} role="main">
        <ProjectArticle>
          <ProjectHeader>
            <HeaderImg css={css`
                @media (min-width: 600px){
                  /*props => props.theme.breakpoints.s*/
                  animation: ${headerImgAnim} 0.6s ease;
                }
              `}>
              <Img fluid={data.image.localFile.childImageSharp.fluid} />
            </HeaderImg>
            <HeaderText>
              <h1>{data.title.text}</h1>
              <HeadlineWrapper>
                <DescriptionPair icon={<DateIcon/>} content={data.date}/>
                <DescriptionPair icon={<AuthorIcon/>} content={
                                <DescriptionCoolLink>
                                  <LocalizedLink to={`/`}>Pedro Almeida</LocalizedLink>
                                </DescriptionCoolLink>}/>
                <Categories categories={categories}/>
              </HeadlineWrapper>
            </HeaderText>
          </ProjectHeader>
          <SliceZone allSlices={data.body} />
        </ProjectArticle>
        <ProjectAside>
            <Title style={{ marginTop: '3rem' }}>{i18n.pages.projects.other}</Title>
            <ProjectListing projects={projects.edges} />
        </ProjectAside>
      </ProjectWrapper>
    </>
  )
}

export default Project

Project.propTypes = {
  data: PropTypes.shape({
    prismicProject: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!, $locale: String!) {
    prismicProject(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        description
        date(formatString: "MMM YYYY")
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        categories {
          category {
            document {
              uid
              data {
                name
              }
            }
          }
        }
        body {
          ... on PrismicProjectBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicProjectBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    projects: allPrismicProject(limit: 2, sort: { fields: [data___date], order: DESC }, filter: { lang: { eq: $locale }, uid: {ne: $uid }}) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description
            date(formatString: "DD.MM.YYYY")
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            categories {
              category {
                document {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
